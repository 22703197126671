<template lang="pug">
.container.bg-white.rounded.pt-2
  consultant-dialog(ref="consultant-dialog" :data="consultants")
  .row.d-block.d-sm-none.my-3
    .col
      b-button-group.special
        router-link.btn.btn-secondary.active(:to="{\
          name: 'center-all-consultant-schedule-week',\
        }") Week
        router-link.btn.btn-secondary(:to="{\
          name: 'center-all-consultant-schedule-day',\
        }") Day
  .row.d-block.d-sm-none.my-3
    .col.d-flex.justify-content-between.align-items-center
      router-link.btn.btn-secondary(:to="{\
        name: 'center-all-consultant-schedule-week',\
        query: { start: previousRange.start, end: previousRange.end }\
      }") <
      h2(v-if="range") {{ `${toMMDD(range.start)} - ${toMMDD(range.end)}` }}
      router-link.btn.btn-secondary(:to="{\
        name: 'center-all-consultant-schedule-week',\
        query: { start: nextRange.start, end: nextRange.end }\
      }") >
  .row.d-none.d-sm-block
    .col.my-3
      .d-flex.justify-content-between.align-items-center
        b-button-group
          router-link.btn.btn-secondary(:to="{\
            name: 'center-all-consultant-schedule-week',\
            query: { start: previousRange.start, end: previousRange.end }\
          }") Previous
          router-link.btn.btn-secondary(:to="{\
            name: 'center-all-consultant-schedule-week',\
            query: { start: nextRange.start, end: nextRange.end }\
          }") Next
        h2(v-if="range") {{ `${range.start} - ${range.end}` }}
        b-button-group
          router-link.btn.btn-secondary.active(:to="{\
            name: 'center-all-consultant-schedule-week',\
          }") Week
          router-link.btn.btn-secondary(:to="{\
            name: 'center-all-consultant-schedule-day',\
          }") Day
  .row
    .col
      fixed-table(
        :top-headers="currentWeekDay"
        :left-headers="timeSlot"
        :data="data"
        :self-render="false"
      )
        template(#topHeader="{ data }")
          .d-flex.justify-content-center.align-items-center {{ data }}
        template(#leftHeader="{ data }")
          .d-flex.justify-content-center.align-items-center {{ data }}
        template(#body="{ data: { total, timestamp } }")
          .d-flex.justify-content-center.align-items-center.test-hover(
            @click="getTimestampConsultants(timestamp)"
          )
            p.h4.my-1 {{ total }}
</template>

<script>
import FullCalendar from '@fullcalendar/vue';

import FixedTable from '@/components/fixedTable/index.vue';
import ConsultantDialog from '@/components/consultantDialog/index.vue';

import moment from 'moment';

import api from '@lioshutan/api-package';
import storage from '@/utils/localStorage';
import {
  getNotRestTimeSlotString,
} from '@/utils/schedule';

export default {

  components: {
    FullCalendar,
    FixedTable,
    ConsultantDialog,
  },

  data() {
    return {
      timeSlot: getNotRestTimeSlotString(),
      data: [],
      consultants: [],
    };
  },

  asyncComputed: {
    async range() {
      const loading = this.$loading.show();

      let start = new Date();
      start.setDate(start.getDate() - start.getDay());

      let end = new Date();
      end.setDate(end.getDate() + 6);

      const startStr = this.$route.query['start'] ?? moment(start).format('YYYY-MM-DD');
      const endStr = this.$route.query['end'] ?? moment(end).format('YYYY-MM-DD');

      start = new Date(startStr);
      end = new Date(endStr);

      await this.getData(start, end);

      loading.hide();

      return {
        start: startStr,
        end: endStr,
      };
    },
  },

  computed: {
    previousRange() {
      if (!this.range) {
        return {
          start: moment(new Date()).format('YYYY-MM-DD'),
          end: moment(new Date()).format('YYYY-MM-DD'),
        };
      }

      const end = new Date(this.range.start);
      end.setDate(end.getDate() - 1);

      const start = new Date(end);
      start.setDate(start.getDate() - 6);
      return {
        start: moment(start).format('YYYY-MM-DD'),
        end: moment(end).format('YYYY-MM-DD'),
      };
    },
    nextRange() {
      if (!this.range) {
        return {
          start: moment(new Date()).format('YYYY-MM-DD'),
          end: moment(new Date()).format('YYYY-MM-DD'),
        };
      }

      const start = new Date(this.range.end);
      start.setDate(start.getDate() + 1);

      const end = new Date(start);
      end.setDate(end.getDate() + 6);

      return {
        start: moment(start).format('YYYY-MM-DD'),
        end: moment(end).format('YYYY-MM-DD'),
      };
    },
    currentWeekDay() {
      if (!this.range) {
        return Array(7).fill().map((tmp, index) => {
          const time = new Date();
          time.setDate(time.getDate() + index);
          return moment(time).format('YYYY-MM-DD');
        });
      }

      return Array(7).fill().map((tmp, index) => {
        const time = new Date(this.range.start);
        time.setDate(time.getDate() + index);
        return moment(time).format('YYYY-MM-DD');
      });
    },
  },
  async created() {
  },
  methods: {
    show() {
      this.$refs['consultant-dialog']['show']();
    },
    hide() {
      this.$refs['consultant-dialog']['hide']();
    },
    async getTimestampConsultants(timestamp) {
      console.log('test', timestamp);
      const loading = this.$loading.show();
      this.consultants = (await api
        .setDNS(process.env.VUE_APP_BASE_API)
        .consultant(storage.token)
        .getTimestampConsultants(timestamp))
        .map(({
          consultantUserId,
          email,
          grade,
          hbConsultantName,
          isDemo,
          maxLevel,
          minLevel,
          nationality,
          picture,
          sevenDays,
          workPhone,
        }) => ({
          id: consultantUserId,
          name: hbConsultantName,
          email,
          level: {
            max: maxLevel,
            min: minLevel,
          },
          picture,
          phone: workPhone,
          demo: isDemo === 1,
          grade: grade.toUpperCase(),
        }));
      loading['hide']();

      this.show();
    },
    toMMDD(date) {
      return moment(date).format('MM-DD');
    },
    async getData(start, end) {
      const response = await api
        .setDNS(process.env.VUE_APP_BASE_API)
        .consultant(storage.token)
        .getOnlineConsultant(start, end);
      console.log(response);
      this.data = response.map(({ statistics }) => ([
        ...statistics.sort((a, b) => {
          if (a.timeslot === 31) {
            return -1;
          }

          return a.timeslot - b.timeslot;
        }).map(({ hbfTotal, timestamp }) => ({
          total: hbfTotal,
          timestamp,
        })),
      ]));
    },
  },
};
</script>

<style lang="scss" scoped>
.test-hover:hover {
  background-color: #d35f5f6b;
}
</style>
