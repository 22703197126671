<template lang="pug">
b-modal(ref="consultant-dialog" centered title="Timeslot Consultant List" ok-only)
  b-card.overflow-hidden(
    v-for="(data, index) in data" :key="index"
    no-body
    style="max-width: 500px;"
  )
    b-row(no-gutters)
      b-col(md="6")
        b-card-img.rounded-0(:src="data.picture" alt="Image")
      b-col(md="6")
        b-card-body
          h2.m-0.p-0 {{ data.name }}
          b
            p.m-0.p-0 {{ data.email }}
          b
            p.m-0.p-0 {{ data.phone }}
          p.m-0.p-0
            | Grade&nbsp;
            b {{ grade }}
          p.m-0.p-0
            | Max Level&nbsp;
            b {{ data.level.max }}
          p.m-0.p-0
            | Min Level&nbsp;
            b {{ data.level.min }}
          p.m-0.p-0
            | Demo&nbsp;
            b {{ data.demo ? 'Yes' : 'No' }}
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => ([]),
    },
  },
  methods: {
    show() {
      console.log('show');
      this.$refs['consultant-dialog']['show']();
    },
    hide() {
      console.log('hide');
      this.$refs['consultant-dialog']['hide']();
    },
  },
};
</script>
