var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "consultant-dialog",
    attrs: {
      "centered": "",
      "title": "Timeslot Consultant List",
      "ok-only": ""
    }
  }, _vm._l(_vm.data, function (data, index) {
    return _c('b-card', {
      key: index,
      staticClass: "overflow-hidden",
      staticStyle: {
        "max-width": "500px"
      },
      attrs: {
        "no-body": ""
      }
    }, [_c('b-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('b-card-img', {
      staticClass: "rounded-0",
      attrs: {
        "src": data.picture,
        "alt": "Image"
      }
    })], 1), _c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('b-card-body', [_c('h2', {
      staticClass: "m-0 p-0"
    }, [_vm._v(_vm._s(data.name))]), _c('b', [_c('p', {
      staticClass: "m-0 p-0"
    }, [_vm._v(_vm._s(data.email))])]), _c('b', [_c('p', {
      staticClass: "m-0 p-0"
    }, [_vm._v(_vm._s(data.phone))])]), _c('p', {
      staticClass: "m-0 p-0"
    }, [_vm._v("Grade "), _c('b', [_vm._v(_vm._s(_vm.grade))])]), _c('p', {
      staticClass: "m-0 p-0"
    }, [_vm._v("Max Level "), _c('b', [_vm._v(_vm._s(data.level.max))])]), _c('p', {
      staticClass: "m-0 p-0"
    }, [_vm._v("Min Level "), _c('b', [_vm._v(_vm._s(data.level.min))])]), _c('p', {
      staticClass: "m-0 p-0"
    }, [_vm._v("Demo "), _c('b', [_vm._v(_vm._s(data.demo ? 'Yes' : 'No'))])])])], 1)], 1)], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }